import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Swal from "sweetalert2";
import { Delete, Error, Info, Note, Success } from "../utils/Alert";


function GoogleCalenderEvent({ updateParentState }) {

  const login = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/calendar.events',
    onSuccess: (codeResponse) => {
      
      updateParentState({popup:false, access_token: codeResponse?.access_token, sync_on: "google" });
    
    },
    onError: (error) => {    
      updateParentState({popup:false, access_token: "" , sync_on: "undefined"  });
    }
});


  return (
    <div>
    <button onClick={() => login()}> <i class="fa-brands fa-google"></i> &nbsp; Google Calendar  </button>
    </div>
  );

}

export default GoogleCalenderEvent;