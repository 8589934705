import DateFormat from "../../utils/DateFormat";
import { likeDislikePost } from "../../features/postSlice";
import { open } from "../../features/eventSlice";
import { useDispatch, useSelector } from "react-redux";
import { PostMedia, PostComments, PostTabs, PostGroups, PostDescription, PostInteraction, PostOps, PostTitle } from "./postBlocks";
import { PostPermission } from "../../utils/Permissions";
import { useState } from "react";
import Swal from "sweetalert2";
import { Info, Error, Note, Success } from "../../utils/Alert";
import Post from "../../services/post.service";
import { Spinner, Modal } from "react-bootstrap";
import GoogleCalenderEvent from "../../components/GoogleCalenderEvent";
import { GoogleOAuthProvider } from '@react-oauth/google';
import OutlookAuth from "../../components/modalBlocks/OutlookAuth";
import { downloadICS } from "../../utils/Ics";
import { useNavigate } from "react-router-dom";
import { showPostError } from "../../utils/PostError";


const CalendarPost = ({ data }) => {

  const dispatch = useDispatch();
  const [showComment, setShowComment] = useState(false);
  const { profile } = useSelector((state) => state?.auth);
  let rsvpAndPay = data?.rsvp && data?.paid_events;
  let rsvp = data?.rsvp && !data?.paid_events;
  let pay = !data?.rsvp && data?.paid_events;
  let { showEdit, showDelete, showEvent } = PostPermission("calendar", data);
  const [likebuttonclicked, setLikeClicked] = useState(false);
  const navigate = useNavigate();
  const user_role = profile?.roledata?.find((item) => item?.instituteid === data?.instituteid)?.role;
  const [syncShow, setSyncShow] = useState(false);
  const [syncToken, setSyncToken] = useState(null);
  const [syncOn, setSyncOn] = useState(null);
  const syncCalendar = (data?.added_by_role == user_role) ? data?.sync_on : "";
  const [deleteData, setDeleteData] = useState(false)


  const updateParentState = (newState, postData) => {
    setSyncShow(newState?.popup);
    setSyncToken(newState?.access_token);
    setSyncOn(newState?.sync_on);

    Post.updateCalender({ 'sync_id': (postData?.sync_id || postData?.postevent[0]?.sync_id), 'sync_on': newState?.sync_on, 'access_token': newState?.access_token })
      .then((res) => {
        setDeleteData(res?.data?.deleteData)
        Swal.fire({ text: "Calendar updated successfully", ...Success });
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        if (message) {
          Swal.fire({ text: message, ...Error });
        } else Swal.fire({ text: Note?.Err, ...Error });
      });
  };
  const handleLike = () => {
    if (!likebuttonclicked) {
      setLikeClicked(true);

      dispatch(likeDislikePost({ postid: data?.id, title: data?.title, likeflag: data?.likeflag ? true : false }))
        .finally(() => setLikeClicked(false));
    }
  };

  const handleRemindEvent = (event) => dispatch(open({ event, id: data?.id, data }));

  const handleEvent = (event) => {

    let check = profile?.roledata?.find((item) => item?.instituteid === data?.instituteid);

    if (event == 'rsvp') {
      dispatch(open({ event, data, check }));
      return false;
    }

    if (check?.stripe_account_id) {
      dispatch(open({ event, data, check }));
    }
    else {
      let title = check?.role === "admin"
        ? "Financial transactions are not enabled. Please add your bank or debit card information."
        : "Financial transactions are not enabled. Please contact your Administrator.";
      Swal.fire({ title, ...Info });
    }
  };

  const handlePaidEvent = (event) => {
    let member = profile?.roledata?.find((item) => item?.instituteid === data?.instituteid);
    let login_user_id = profile?.id;
    let is_login_user_already_paid = data?.eventusers?.filter(Boolean).find((x) => x?.id === login_user_id);
    let login_user_response_on_post = data?.postevent?.filter(Boolean).find((x) => x?.memberid === member?.memberid);

    let message = null;
    if (member?.stripe_account_id == null || member?.stripe_account_id == "") {
      message =
        member?.role === "admin"
          ? "Financial transactions are not enabled. Please add your bank or debit card information."
          : "Financial transactions are not enabled. Please contact your Administrator.";
    }

    if (message != null) {
      Swal.fire({ text: message ?? Note?.Err, ...Error });
      const user = profile?.roledata?.find((row) => row?.instituteid === data?.instituteid);
      if (showPostError(user, navigate))
    {  return false;}
    }
    if (event == 'rsvpAndPay' && is_login_user_already_paid && login_user_response_on_post?.response == 'yes') {
      Swal.fire({ text: "Payment has already been made. Multiple payments are not allowed." ?? Note?.Err, ...Error });
      return false;
    }
    if (event == 'pay' && is_login_user_already_paid) {
      Swal.fire({ text: "Payment has already been made. Multiple payments are not allowed." ?? Note?.Err, ...Error });
      return false;
    }

    dispatch(open({ event, data, member }));

  };

  return (
    <div className="all-posts-box similar-shadow-2 mb-3 border-box">
      <PostOps data={data} showEdit={showEdit} showDelete={showDelete} />
      <PostTitle data={data} />
      <PostGroups data={data} />
      <PostTabs data={data} />
      <ul className="time-info d-flex mb-3 ps-0">
        <li>
          <i className="ti ti-calendar"></i> {DateFormat(data?.start_date, true)}
        </li>
        <li>
          <i className="ti ti-map-pin"></i> {data?.location}
        </li>
        {data?.paid_events ? (
          <>
            <li>
              <p className="me-1">
                <b>Payment Amount: </b>
                {data?.paid_event_amount ? `$${parseFloat(data.paid_event_amount).toFixed(2)}` : "$0.00"}
              </p>
            </li>

            <li>
              <p className="me-1"><b>Due: </b>{DateFormat(data?.due_date, true)}</p>

            </li>
          </>
        ) : null}
      </ul>

      <PostDescription data={data} />
      <PostMedia data={data} />
      {!data?.is_cancelled &&
        <PostInteraction data={data}>
          <ul className="d-flex">
            <li className={data?.likeflag ? "blue-thumb" : ""} onClick={handleLike}>
              <button>
                <i className="ti ti-thumb-up"></i> {data?.like_count}
              </button>
            </li>
            {!data?.disable_comments && (
              <li>
                <button onClick={() => setShowComment(!showComment)}>
                  <i className="ti ti-message-dots"></i> {data?.comment_count}
                </button>
              </li>
            )}
          </ul>

          <div className="d-flex align-items-center bell-noti-main">
            {showEvent && rsvp && !(user_role === 'teacher' && data?.added_by_role === 'admin') && (
              <button onClick={() => handleEvent("rsvp")}>RSVP</button>
            )}
            {showEvent && rsvpAndPay && !(user_role === 'teacher' && data?.added_by_role === 'admin') && (
              <button onClick={() => handlePaidEvent("rsvpAndPay")}>RSVP & PAY</button>
            )}
            {showEvent && pay && !(user_role === 'teacher' && data?.added_by_role === 'admin') && (
              <button onClick={() => handlePaidEvent("pay")}>PAY</button>
            )}
            <button onClick={() => handleRemindEvent("remindMe")} className={data?.tags[0]?.remind_me ? "bell-noti-blue ms-2" : "bell-noti ms-2"}>
              <i className="ti ti-bell-ringing"></i>
            </button>
          </div>

        </PostInteraction>
      }
      {data?.is_cancelled && deleteData==false && (syncCalendar || (data?.postevent?.find(event => event?.userid === profile?.id)?.sync_on))  &&

        <div className="all-posts-footer d-flex justify-content-between">
          <div className="d-flex align-items-center bell-noti-main">

            <button onClick={() => setSyncShow(true)}>Update Calendar</button>

            <Modal show={syncShow} onHide={() => { setSyncShow(false); }}>
              <Modal.Header closeButton>
                <Modal.Title>Add to calendar</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {(syncCalendar ? syncCalendar == "Apple" : (data?.postevent?.find(event => event?.userid === profile?.id)?.sync_on === 'Apple')) &&
                  <div className="syncButton" style={{ textAlign: "center" }}>
                    <button onClick={() => {
                      setSyncShow(false);
                      setSyncToken("");
                      setSyncOn('Apple');
                      downloadICS(data);
                      navigate("/home");

                    }}><i className="fa fa-calendar" aria-hidden="true"></i> &nbsp; Calendar apps (ICS) </button>
                  </div>

                }

                {(syncCalendar ? syncCalendar == "google" : (data?.postevent?.find(event => event?.userid === profile?.id)?.sync_on === 'google')) &&

                  <div className='syncButton' style={{ textAlign: "center" }}>
                    <GoogleOAuthProvider clientId="806967399943-pr80m53f83bofv3ghaoijo59bvnjuopb.apps.googleusercontent.com">
                      <GoogleCalenderEvent updateParentState={(newState) => updateParentState(newState, data)}></GoogleCalenderEvent>
                    </GoogleOAuthProvider>
                  </div>

                }

                <br></br>

                {(syncCalendar ? syncCalendar == "outlook" : (data?.postevent?.find(event => event?.userid === profile?.id)?.sync_on === 'outlook')) &&

                  <OutlookAuth updateParentState={(newState) => updateParentState(newState, data)} />

                }
              </Modal.Body>
            </Modal>

          </div>
        </div>

      }

      {!data?.disable_comments && showComment && <PostComments data={data} />}
    </div>
  );
};

export default CalendarPost;
